import React, { useState } from "react";
import { NavLink, useLocation, useHistory, Link } from "react-router-dom";

//Okta related packages

import UserInfo from "../../components/user-info/user-info";
import { useSelector } from "react-redux";
import usePracticeHook from "../../practice";
import userRoles from "../../role";

// SVG Icons
import Dashboard from "../../assets/svg/Sidebar/Dashboard.svg";
import Calendar from "../../assets/svg/Sidebar/Schedule.svg";
import Patients from "../../assets/svg/Sidebar/Patients.svg";
import Handovers from "../../assets/svg/Sidebar/Handovers.svg";
import Support from "../../assets/svg/Sidebar/Support.svg";
import Kompliwise from "../../assets/svg/Sidebar/Kompliwise.svg";
import Podiatry from "../../assets/svg/Sidebar/Podiatry.svg";
import Dietetics from "../../assets/svg/Sidebar/Dietetics.svg";
import ProgressNotes from "../../assets/svg/Sidebar/ProgressNotes.svg";
import Assessment from "../../assets/svg/Sidebar/Assessments.svg";
import CarePlans from "../../assets/svg/Sidebar/Careplans.svg";
import Account from "../../assets/svg/Sidebar/Account.svg";
import Subscribe from "../../assets/svg/Sidebar/Subscription.svg";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, far, fas);

const SideBar = ({ setIsNavClosed, saved, setSaved }) => {
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const userClaims = JSON.parse(localStorage.getItem("user-claims"));
  const clientApp = userClaims && userClaims?.clientApp;
  const isHapiPartnerUser =
    clientApp && clientApp.some((data) => data.appName === "HAPIPARTNERS");
  const kompliwiseURL = process.env.REACT_APP_KOMPLIWISE_URL;
  const { hasPhysioPractice, hasDiatecianPractice } = usePracticeHook();
  const {
    adminRole,
    clinicalManagerRole,
    facilityManagerRole,
    careHomeManagerRole,
  } = userRoles();
  let history = useHistory();
  // const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles);
  const location = useLocation();

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  const handleChangeURL = (e, url) => {
    e.preventDefault();
    if (!saved) {
      if (
        !window.confirm(
          "Are you sure you want to leave this page? Unsaved changes will be discarded"
        )
      )
        return;
    }
    setSaved(true);
    history.push(url);

    // reload page if your last route is /support or /login
    if (
      // location.pathname.includes("/support/contact") ||
      location.pathname.includes("/login")
    ) {
      window.location.reload();
    }

    // if (
    //   !window.location.pathname.includes("/support/contact") ||
    //   !window.location.pathname.includes("/login")
    // ) {
    //   window.location.reload();
    // }
  };

  return (
    <div style={{ marginBottom: "100px" }}>
      <button
        className="border border-0 rounded-pill bg-light text-dark pt-2 mt-1"
        onClick={() => setIsNavClosed(true)}
      >
        <i className="far fa-times-circle h4"></i>
        <span className="orange-1 font-weight-bold ml-1 align-top">Close</span>
      </button>
      {userClaims ? (
        <>
          <UserInfo
            firstName={userClaims.firstName}
            lastName={userClaims.lastName}
            id={userClaims._id}
          />
        </>
      ) : null}
      {userClaims && (
        <div className="mt-5 d-flex flex-column vertical-center pl-5 navbar-nav align-text-sm-start">
          {/* {roles.includes("superadmin") ||
          roles.includes("admin") ||
          roles.includes("partner") ||
          roles.includes("supermanager") ? ( */}
          {/* <li
            className={
              'nav-item'
            }
          >
            <NavLink
              className="nav-link sidebar-link d-flex"
              exact
              to={'/landing-page'}
              onClick={(e) => handleChangeURL(e, '/landing-page')}
            >
              <img
                src={Dashboard}
                alt="Dashboard"
                className="svg-sidebar"
              />
              HAPIYOU
            </NavLink>
          </li> */}
          {!isHapiPartnerUser ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/landing-page") >= 0
                  ? " active"
                  : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to={"/landing-page"}
                onClick={(e) => handleChangeURL(e, "/landing-page")}
              >
                <img src={Dashboard} alt="Dashboard" className="svg-sidebar" />
                DASHBOARD
              </NavLink>
            </li>
          )}
          {/* ) : null} */}
          {/* {roles.includes("superadmin") ||
          roles.includes("admin") ||
          roles.includes("partner") ||
          roles.includes("supermanager") ? ( */}
          {!isHapiPartnerUser ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/schedules") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to="/schedules"
                onClick={(e) => handleChangeURL(e, "/schedules")}
              >
                <img src={Calendar} alt="Schedule" className="svg-sidebar" />
                SCHEDULE
              </NavLink>
            </li>
          )}
          {!isHapiPartnerUser ? null : (
            <>
              {adminRole ||
              facilityManagerRole ||
              clinicalManagerRole ||
              careHomeManagerRole ? (
                <>
                  <li
                    className={`nav-item ${
                      location.pathname.indexOf("/facilities") >= 0
                        ? "active"
                        : ""
                    }`}
                  >
                    <a
                      className="nav-link sidebar-link d-flex"
                      href="#facilityDropdown"
                      data-toggle="collapse"
                    >
                      <FontAwesomeIcon
                        icon={["fa", "hospital"]}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "15px",
                          marginTop: "1px",
                        }}
                      />
                      FACILITIES
                    </a>
                  </li>
                  <div
                    className={`collapse ${
                      location.pathname.indexOf("/facilities") >= 0
                        ? "show"
                        : ""
                    }`}
                    id="facilityDropdown"
                  >
                    {/* Podiatrist */}
                    <li
                      className={
                        "nav-item pl-2" +
                        (location.pathname.indexOf("/facilities/podiatry") >= 0
                          ? " active"
                          : "")
                      }
                    >
                      <a
                        className="nav-link sidebar-link d-flex"
                        href="#podiatryDropdown"
                        data-toggle="collapse"
                      >
                        <img
                          src={Podiatry}
                          alt="Podiatry"
                          className="svg-sidebar"
                          style={{ color: "black" }}
                        />
                        PODIATRY
                      </a>
                    </li>
                    <div
                      className={`collapse${
                        location.pathname.indexOf("/facilities/podiatry") >= 0
                          ? "show"
                          : ""
                      }`}
                      id="podiatryDropdown"
                    >
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/podiatry/progressNotes"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/podiatry/progressNotes?page=1&podiatrist=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/podiatry/progressNotes?page=1&podiatrist=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={ProgressNotes}
                            alt="Progress-Notes"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          PROGRESS NOTES
                        </NavLink>
                      </li>
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/podiatry/assessments"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/podiatry/assessments?page=1&podiatrist=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/podiatry/assessments?page=1&podiatrist=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={Assessment}
                            alt="Assessment"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          ASSESSMENTS
                        </NavLink>
                      </li>
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/podiatry/carePlans"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/podiatry/carePlans?page=1&podiatrist=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/podiatry/carePlans?page=1&podiatrist=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={CarePlans}
                            alt="CarePlans"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          CARE PLANS
                        </NavLink>
                      </li>
                    </div>
                    {/* Physiotherapy */}
                    <li
                      className={
                        "nav-item pl-2" +
                        (location.pathname.indexOf(
                          "/facilities/physiotherapy"
                        ) >= 0
                          ? " active"
                          : "")
                      }
                    >
                      <a
                        className="nav-link sidebar-link d-flex"
                        href="#physiotherapyDropdown"
                        data-toggle="collapse"
                      >
                        <FontAwesomeIcon
                          icon={["fa", "user"]}
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "15px",
                            marginTop: "1px",
                          }}
                        />
                        PHYSIOTHERAPY
                      </a>
                    </li>
                    <div
                      className={`collapse ${
                        location.pathname.indexOf(
                          "/facilities/physiotherapy"
                        ) >= 0
                          ? "show"
                          : ""
                      }`}
                      id="physiotherapyDropdown"
                    >
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/physiotherapy/progressNotes"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/physiotherapy/progressNotes?page=1&physiotherapist=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/physiotherapy/progressNotes?page=1&physiotherapist=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={ProgressNotes}
                            alt="Progress-Notes"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          PROGRESS NOTES
                        </NavLink>
                      </li>
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/physiotherapy/assessments"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/physiotherapy/assessments?page=1&physiotherapist=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/physiotherapy/assessments?page=1&physiotherapist=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={Assessment}
                            alt="Assessment"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          ASSESSMENTS
                        </NavLink>
                      </li>
                    </div>
                    {/* Dietetics */}
                    <li
                      className={
                        "nav-item pl-2" +
                        (location.pathname.indexOf("/facilities/dietetics") >= 0
                          ? " active"
                          : "")
                      }
                    >
                      <a
                        className="nav-link sidebar-link d-flex"
                        href="#dieteticsDropdown"
                        data-toggle="collapse"
                      >
                        <img
                          src={Dietetics}
                          alt="dietetics"
                          className="svg-sidebar"
                          style={{ color: "black", weight: "40px" }}
                        />
                        DIETETICS
                      </a>
                    </li>
                    <div
                      className={`collapse ${
                        location.pathname.indexOf("/facilities/dietetics") >= 0
                          ? "show"
                          : ""
                      }`}
                      id="dieteticsDropdown"
                    >
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/dietetics/progressNotes"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/dietetics/progressNotes?page=1&dietitian=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/dietetics/progressNotes?page=1&dietitian=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={ProgressNotes}
                            alt="Progress-Notes"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          PROGRESS NOTES
                        </NavLink>
                      </li>
                      <li
                        className={
                          "nav-item pl-4" +
                          (location.pathname.indexOf(
                            "/facilities/dietetics/assessments"
                          ) >= 0
                            ? " active"
                            : "")
                        }
                      >
                        <NavLink
                          className="nav-link sidebar-link d-flex"
                          exact
                          to={
                            "/facilities/dietetics/assessments?page=1&dietitian=null&facility=null"
                          }
                          onClick={(e) =>
                            handleChangeURL(
                              e,
                              "/facilities/dietetics/assessments?page=1&dietitian=null&facility=null"
                            )
                          }
                        >
                          <img
                            src={Assessment}
                            alt="Assessment"
                            className="svg-sidebar"
                            style={{ color: "black" }}
                          />
                          ASSESSMENTS
                        </NavLink>
                      </li>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          {!isHapiPartnerUser ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/patients") >= 0 ? " active" : "")
              }
            >
              {hasPhysioPractice ? (
                <NavLink
                  className="nav-link sidebar-link d-flex"
                  exact
                  to="/patients?page=1&patientId=null&tab=2&facility=null&filter=all&ward=null&funding=null"
                  onClick={(e) =>
                    handleChangeURL(
                      e,
                      "/patients?page=1&patientId=null&tab=2&facility=null&filter=all&ward=null&funding=null"
                    )
                  }
                >
                  <img src={Patients} alt="Dashboard" className="svg-sidebar" />
                  PATIENTS
                </NavLink>
              ) : (
                <NavLink
                  className="nav-link sidebar-link d-flex"
                  exact
                  to="/patients?page=1&patientId=null&tab=1&facility=null&filter=all&ward=null&funding=null"
                  onClick={(e) =>
                    handleChangeURL(
                      e,
                      "/patients?page=1&patientId=null&tab=1&facility=null&filter=all&ward=null&funding=null"
                    )
                  }
                >
                  <img src={Patients} alt="Dashboard" className="svg-sidebar" />
                  PATIENTS
                </NavLink>
              )}
            </li>
          )}
          {!isHapiPartnerUser || careHomeManagerRole || adminRole ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/tasks") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to={"/tasks"}
                onClick={(e) => handleChangeURL(e, "/tasks")}
              >
                {/* <img src={Dashboard} alt="Dashboard" className="svg-sidebar" /> */}
                <FontAwesomeIcon
                  icon={["fa", "clipboard-list"]}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "15px",
                    marginTop: "1px",
                  }}
                />
                TASKS
              </NavLink>
            </li>
          )}
          {!isHapiPartnerUser ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/chat") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to={"/chat?channelId=null"}
                onClick={(e) => handleChangeURL(e, "/chat?channelId=null")}
              >
                {/* <img src={Dashboard} alt="Dashboard" className="svg-sidebar" /> */}
                <FontAwesomeIcon
                  icon={["fa", "comments"]}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "15px",
                    marginTop: "1px",
                  }}
                />
                CHAT
              </NavLink>
            </li>
          )}
          {/* ) : null} */}
          {/* {roles.includes("superadmin") && (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/users") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link"
                exact
                to="/users"
                onClick={(e) => handleChangeURL(e, "/users")}
              >
                <FontAwesomeIcon
                  icon={["fa", "users"]}
                  className="mr-3 nav-spacing"
                />
                USERS
              </NavLink>
            </li>
          )} */}
          {/* {roles.includes("superadmin") ||
          roles.includes("admin") ||
          roles.includes("partner") ||
          roles.includes("supermanager") ? ( */}
          {/* // {getHand} */}
          {!isHapiPartnerUser ? null : hasPhysioPractice ||
            hasDiatecianPractice ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/handovers") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to={`/handovers?page=1&facility=null&fromDate=null&toDate=null`}
                onClick={(e) =>
                  handleChangeURL(
                    e,
                    "/handovers?page=1&facility=null&fromDate=null&toDate=null"
                  )
                }
              >
                <img src={Handovers} alt="Schedule" className="svg-sidebar" />
                HANDOVER
              </NavLink>
            </li>
          )}
          {/* {hasDiatecianPractice ? (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/treatments") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to={`/treatments`}
                onClick={(e) =>
                  handleChangeURL(
                    e,
                    "/treatments"
                  )
                }
              >
                <img src={Handovers} alt="Schedule" className="svg-sidebar" />
                HANDOVER
              </NavLink>
            </li>
          ) : null } */}
          {/* ) : null} */}
          {/* {roles.includes("superadmin") ||
        roles.includes("admin") ||
        roles.includes("partner") ||
        roles.includes("supermanager") ? (
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/invoices") >= 0 ? " active" : "")
            }
          >
            <NavLink className="nav-link sidebar-link" exact to="/invoices">
              <FontAwesomeIcon
                icon={["fas", "scroll"]}
                className="mr-3 nav-spacing"
              />
              INVOICES
            </NavLink>
          </li>
        ) : null} */}
          {/* {roles.includes("superadmin") ||
          roles.includes("admin") ||
          roles.includes("partner") ||
          roles.includes("supermanager") ? ( */}
          {!isHapiPartnerUser ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/support") >= 0 ? " active" : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to="/support/contact"
                onClick={(e) => handleChangeURL(e, "/support/contact")}
              >
                <img src={Support} alt="Support" className="svg-sidebar" />
                SUPPORT
              </NavLink>
            </li>
          )}
          {/* ) : null} */}
          {!isHapiPartnerUser || careHomeManagerRole || adminRole ? null : (
            <li className="nav-item">
              <a
                className="nav-link sidebar-link d-flex"
                href={`${kompliwiseURL}/app/validate?q=${authState.access_token}`}
                target="_blank"
              >
                <img
                  src={Kompliwise}
                  alt="Kompliwise"
                  className="svg-sidebar"
                />
                KOMPLIWISE
              </a>
            </li>
          )}
          {!isHapiPartnerUser ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/notifications") >= 0
                  ? " active"
                  : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to="/notifications?page=1"
                onClick={(e) => handleChangeURL(e, "/notifications?page=1")}
              >
                <FontAwesomeIcon
                  icon={["fa", "bell"]}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "15px",
                    marginTop: "1px",
                  }}
                />
                NOTIFICATIONS
              </NavLink>
            </li>
          )}
          {/* {!adminRole ? null : (
            <>
              <li
                className={
                  "nav-item" +
                  (location.pathname.indexOf("/account") >= 0 ? " active" : "")
                }
              >
                <a
                  className="nav-link sidebar-link d-flex"
                  href="#accountDropdown"
                  data-toggle="collapse"
                >
                  <FontAwesomeIcon
                    icon={["fa", "user"]}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "15px",
                      marginTop: "1px",
                    }}
                  />
                  ACCOUNT
                </a>
              </li>
              <div
                className={`collapse ${
                  location.pathname.indexOf("/account") >= 0 ? "show" : ""
                }`}
                id="accountDropdown"
              >
                <li
                  className={
                    "nav-item pl-2" +
                    (location.pathname.indexOf("/account/register") >= 0
                      ? " active"
                      : "")
                  }
                >
                  <NavLink
                    className="nav-link sidebar-link d-flex"
                    exact
                    to={"/account/register"}
                    onClick={(e) => handleChangeURL(e, "/account/register")}
                  >
                    <img src={Account} alt="account" className="svg-sidebar" />
                    REGISTER
                  </NavLink>
                </li>
                <li
                      className={
                        "nav-item pl-2" +
                        (location.pathname.indexOf("/account/lists") >= 0
                          ? " active"
                          : "")
                      }
                    >
                      <NavLink
                        className="nav-link sidebar-link d-flex"
                        exact
                        to={"/account/lists?page=1"}
                        onClick={(e) =>
                          handleChangeURL(e, "/account/lists?page=1")
                        }
                      >
                        <img
                          src={Account}
                          alt="account"
                          className="svg-sidebar"
                        />
                        ACCOUNT LISTS
                      </NavLink>
                    </li>
              </div>
            </>
          )} */}
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/settings") >= 0 ? " active" : "")
            }
          >
            <NavLink
              className="nav-link sidebar-link d-flex"
              exact
              to="/settings"
              onClick={(e) => handleChangeURL(e, "/settings")}
            >
              <FontAwesomeIcon
                icon={["fa", "wrench"]}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "15px",
                  marginTop: "1px",
                }}
              />
              SETTINGS
            </NavLink>
          </li>
          {!isHapiPartnerUser ? null : !careHomeManagerRole ? null : (
            <li
              className={
                "nav-item" +
                (location.pathname.indexOf("/subscriptions") >= 0
                  ? " active"
                  : "")
              }
            >
              <NavLink
                className="nav-link sidebar-link d-flex"
                exact
                to="/subscriptions"
                onClick={(e) => handleChangeURL(e, "/subscriptions")}
              >
                <img
                  src={Subscribe}
                  alt="subscription"
                  className="svg-sidebar"
                />
                SUBSCRIPTIONS
              </NavLink>
            </li>
          )}
          {!isHapiPartnerUser ? null : !adminRole ? null : (
            <>
              <li
                className={
                  "nav-item" +
                  (location.pathname.indexOf("/admin") >= 0 ? " active" : "")
                }
              >
                <a
                  className="nav-link sidebar-link d-flex"
                  href="#adminDropdown"
                  data-toggle="collapse"
                >
                  <FontAwesomeIcon
                    icon={["fa", "user"]}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "15px",
                      marginTop: "1px",
                    }}
                  />
                  ADMIN
                </a>
              </li>
              <div
                className={`collapse ${
                  location.pathname.indexOf("/admin") >= 0 ? "show" : ""
                }`}
                id="adminDropdown"
              >
                <li
                  className={
                    "nav-item pl-2" +
                    (location.pathname.indexOf("/admin/register-carehome") >= 0
                      ? " active"
                      : "")
                  }
                >
                  <NavLink
                    className="nav-link sidebar-link d-flex"
                    exact
                    to={"/admin/register-carehome"}
                    onClick={(e) =>
                      handleChangeURL(e, "/admin/register-carehome")
                    }
                  >
                    <img src={Account} alt="admin" className="svg-sidebar" />
                    REGISTER
                  </NavLink>
                </li>
              </div>
            </>
          )}
          {/* {roles.includes("superadmin") ||
        roles.includes("admin") ||
        roles.includes("partner") ||
        roles.includes("supermanager") ? (
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/terms") >= 0 ? " active" : "")
            }
          >
            <NavLink className="nav-link sidebar-link d-flex" exact to="/terms">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={["fa", "file-contract"]}
                  aria-hidden="true"
                  className="svg-sidebar mr-3"
                  style={{ height: "20px", width: "20px" }}
                />
                <p> TERMS AND CONDITIONS</p>
              </div>
            </NavLink>
          </li>
        ) : null} */}
          {/* {roles.includes("user") && (
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/loved1s") >= 0 ? " active" : "")
            }
          >
            <NavLink className="nav-link sidebar-link" exact to="/loved1s">
              <FontAwesomeIcon
                icon={["fa", "heart"]}
                className="mr-3 nav-spacing"
              />
              LOVED ONES
            </NavLink>
          </li>
        )}
        {roles.includes("superadmin") ||
        roles.includes("admin") ||
        roles.includes("partner") ||
        roles.includes("supermanager") ? (
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/progress") >= 0 ? " active" : "")
            }
          >
            <NavLink className="nav-link sidebar-link" exact to="/progress">
              <FontAwesomeIcon
                icon={["fa", "clipboard"]}
                className="mr-3 nav-spacing"
              />
              PROGRESS NOTES
            </NavLink>
          </li>
        ) : null}
        {roles.includes("superadmin") ||
        roles.includes("admin") ||
        roles.includes("partner") ||
        roles.includes("supermanager") ? (
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/assessments") >= 0 ? " active" : "")
            }
          >
            <NavLink className="nav-link sidebar-link" exact to="/assessments">
              <FontAwesomeIcon
                icon={["fa", "search"]}
                className="mr-3 nav-spacing"
              />
              ASSESSMENTS
            </NavLink>
          </li>
        ) : null}
        {roles.includes("superadmin") ||
        roles.includes("admin") ||
        roles.includes("partner") ||
        roles.includes("supermanager") ? (
          <li
            className={
              "nav-item" +
              (location.pathname.indexOf("/careplans") >= 0 ? " active" : "")
            }
          >
            <NavLink className="nav-link sidebar-link" exact to="/careplans">
              <FontAwesomeIcon
                icon={["fa", "notes-medical"]}
                className="mr-3 nav-spacing"
              />
              CARE PLANS
            </NavLink>
          </li>
        ) : null} */}
          {/* <li className="nav-item">
          <NavLink className="nav-link sidebar-link" exact to="/facilities">
            <FontAwesomeIcon
              icon={["fa", "hospital"]}
              className="mr-3 orange-1 nav-spacing"
            />
            FACILITIES
          </NavLink>
        </li> */}
          {/* <li className={"nav-item"}>
						<div className="dropdown nav-link sidebar-link d-flex">
							<div data-toggle="dropdown">
								<img
									src={Dashboard}
									alt="Dashboard"
									className="svg-sidebar"
								/>
								HAPI HR
							</div>
							<ul className="dropdown-menu">
								<li>HR</li>
								<li>KompliWise</li>
								<li>Journal Club</li>
							</ul>
						</div>
					</li>
					<li className={"nav-item"}>
						<NavLink
							className="nav-link sidebar-link d-flex"
							exact
							to={"/landing-page"}
							onClick={(e) => handleChangeURL(e, "/landing-page")}
						>
							<img
								src={Dashboard}
								alt="Dashboard"
								className="svg-sidebar"
							/>
							HAPI CHAT
						</NavLink>
					</li> */}
          <br />
          {userClaims && authState ? (
            <li className="nav-item mt-3 mb-5 mb-md-1 cursor-pointer">
              <p className="nav-link sidebar-link" onClick={handleExpiration}>
                <FontAwesomeIcon
                  icon={["fa", "sign-out-alt"]}
                  className="mr-3 nav-spacing"
                />
                LOG OUT
              </p>
            </li>
          ) : (
            <li className="nav-item mt-3 mb-5 mb-md-1">
              <NavLink className="nav-link sidebar-link" exact to="/login">
                <FontAwesomeIcon
                  icon={["fa", "sign-in-alt"]}
                  className="mr-3 nav-spacing"
                />
                LOG IN
              </NavLink>
            </li>
          )}
        </div>
      )}
    </div>
  );
};

export default SideBar;
